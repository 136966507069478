import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/royce/src/roycetownsend.com/src/components/layout.tsx";
import Image from '../components/ProfileImage';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Image mdxType="Image" />
    <p>{`When I am not programming (and am awake) I can be found swing dancing,
DJing swing and blues for dancers, running tabletop role playing games,
rock-climbing, cycling, juggling, fire twirling, playing boardgames,
enjoying music, drinking coffee, or listening to a book.`}</p>
    <p>{`You could read my `}<a parentName="p" {...{
        "href": "/files/Resume-RoyceTownsend.pdf"
      }}>{`resume`}</a>{`. I’ve kept it brief.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      